.topnavNUC {
    background-color: white;
}

.cardpills {
    /* height: 40px; */
    background: green;
    border-radius: 10px;
}

.navAdmin {
    background: green;
    min-height: 100vh;
    position: sticky;
}

.navAdmin div {
    margin-bottom: 40px;
}

.OtpComponent input {
    height: 60px;
    width: 60px;
    padding: 10px;
    border: 1px solid green;
    margin: 10px;
    text-align: center;
}

.textSum {
    /* font-weight: bold; */
    font-size: x-small;
}

.texth {
    height: 100px;
}

.themaArea {
    background-color: rgb(152, 152, 252);
    color: white;
    font-weight: bold;
}