.selectedtracks {
    height: 200px;
    width: 200px;
    background: linear-gradient(135deg, #A6CE34 0%, #0A770C 100%);
    border-radius: 5px;
}

.tracks {
    height: 200px;
    width: 200px;
    border: 1px solid #A6CE34;
    border-radius: 5px;
    color: #A6CE34;
    /* background: linear-gradient(135deg, #A6CE34 0%, #0A770C 100%); */
}

/* .tracks a {
    /* height: 200px;
    /* width: 200px;
    border: 1px solid #A6CE34;
    border-radius: 5px; */
/* color: #A6CE34; */
/* background: linear-gradient(135deg, #A6CE34 0%, #0A770C 100%); */
/* } */

.tracks p {
    position: absolute;
    top: 40%;
    color: #A6CE34;
}

.selectedtracks p {
    position: absolute;
    top: 40%;
    color: white;

}

.navbarIVa {
    position: relative;
    background: linear-gradient(135deg, #A6CE34 0%, #0A770C 100%);
    padding: 10px;
    height: 100vh;
    /* width: 100%; */
}

.navbarIVa div {
    margin-bottom: 40px;
}

.navbarIVa li a:hover {
    width: 40px;
    height: 40px;
    transform: scale(1.5);
}

.icons {
    color: white;
    height: 50px;
    transition: all 1s;
}

.icons:hover {
    transform: scale(2.0);

}

.centerdetail {
    /* width: 100%; */
    padding: 20px;
    border: 1px solid #A6CE34;
}

.centerdetail:hover {
    background: linear-gradient(135deg, #A6CE34 0%, #0A770C 100%);
    padding: 20px;
    color: white;
    border: 1px solid #A6CE34;
}

.logo img {
    height: 100px;
}

.navheader {
    background-color: white;
}

.mainbody {
    background-color: rgb(248, 245, 245);
}

.fulloverflow {
    overflow-y: scroll;
    height: 400px;
}

.selected {
    border-bottom: 4px solid green;
    border-top: 4px solid green;


}

.actualborder {
    border-right: 1px solid black;
    border-left: 1px solid black;

}